<template>
  <div class="customer" v-if="customer">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6" order="2" order-md="1">
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="customer.name"
                    label="Farm Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-if="customer.primarycontactid"
                    v-model="customer.primarycontactid.firstname"
                    label="First Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-if="customer.primarycontactid"
                    v-model="customer.primarycontactid.lastname"
                    label="Last Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="customer.address1_line1"
                    label="Address"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="customer.address1_city"
                    label="City"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="customer.address1_stateorprovince"
                    label="State"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="customer.address1_postalcode"
                    label="Zip"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="customer.emailaddress1"
                    label="Email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
        <v-col cols="12" md="6" order="1" order-md="2" >
          <Map v-if="customer.pfs_farmmarketid" :growerId="customer.pfs_farmmarketid" />
          <div v-else class="map-area">
            <h2>Farm Market Unavailable</h2>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="plans">
            <v-card-title align-center>
              <h2 class="mr-1">Plans</h2>
              <v-btn icon color="primary" :to="`/customers/${customer.accountid}/newPlan`"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
              <v-spacer />
              <!-- <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
              ></v-text-field> -->
            </v-card-title>
            <v-data-table
            :headers="planHeaders"
            :items="plans"
            class="elevation-1"
            sort-by="createdAt"
            sort-desc=""
            :items-per-page="10"
            :footer-props="{
            'items-per-page-options': [5, 10, 25, 50, 100]
            }"
            @click:row="routeTo"



            :loading="loading"
            loading-text="Loading Plans..."
            :no-data-text="noPlans"
            >
            <template
              v-slot:item.createdAt="{ item }"
            >
              {{ formatDate(item.createdAt) }}
            </template>
            
            <template
              v-slot:item.edit="{ item }"
            >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click.stop="editRoute(item.id)" icon color="primary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
              
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click.stop="copyPlan(item)" icon color="secondary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>Duplicate Plan</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click.stop="deletePrompt(item)" icon color="red" v-bind="attrs" v-on="on">
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
            </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Map from "@/components/Map"
import dayjs from "dayjs"
export default {
  name: 'Customer',
  async created() {
    if (this.token) {
      this.loading = true
      await this.$store.dispatch("customer/getCustomerData", this.$route.params.id)
      this.loading = false
      if (this.customer.plans) {
        this.plans = this.customer.plans
      }
      this.dataCheck = true
    }
  },
  components: {
    Map
  },
  data() {
      return {
          plans: [],
          planYear: "",
          dataCheck: false,
          loading: false,
          planHeaders: [
            {
                text: "Created",
                value: "createdAt"
            },
            {
                text: "Name",
                value: "name"
            },
            {
                text: "Rep",
                value: "owner.email"
            },
            {
                text: "# of Fields",
                value: "fields.items.length"
            },
            {
                text: "Year",
                value: "year"
            },
            {
                text: "",
                value: "edit"
            }
          ]
        }
  },
  watch: {
    async token(val) {
      this.loading = true
      await this.$store.dispatch("customer/getCustomerData", this.$route.params.id)
      this.loading = false
      if (this.customer.plans) {
        this.plans = this.customer.plans
      }
      this.dataCheck = true
    }
  },
  computed: {
    ...mapState("customer", ["customer"]),
    ...mapState("user", ["token", "user"]),
    noPlans() {
        if (this.dataCheck) {
            return "No Plans Found"
        }
        else {
            return "Loading Plans..."
        }
    }
  },
  methods: {
    async copyPlan(item) {
      let copy = JSON.parse(JSON.stringify(item))
      const { value: formValues } = await this.$swal({
        title: `<strong>COPY ${copy.name}?</strong>`,
        customClass: {
          htmlContainer: "copy-container"
        },
        showCancelButton: true,
        confirmButtonColor: '#015a9c',
        cancelButtonColor: '#757575',
        confirmButtonText: 'COPY',
        cancelButtonText: 'CANCEL',
        html:
        `<label for="swal1-input" class="swal1-input-label">PLAN NAME</label>
        <input value="${copy.name}" id="swal-input1" class="swal2-input">
        <label for="swal2-input" class="swal1-input-label">YEAR</label>
        <input type="number" value="${copy.year}" id="swal-input2" class="swal2-input">`,
        preConfirm: () => {
          return [
            document.getElementById('swal-input1').value,
            document.getElementById('swal-input2').value
          ]
        },
        allowOutsideClick: false
      })
      if (formValues) {
        let [name, year] = formValues
        copy.name = name
        copy.year = Number(year)
        if (copy.year !== item.year) {
          copy.fields.items.forEach(item => item.done = false)
        }
        copy.dealer = this.user.dealerId ? this.user.dealerId : this.user.username
        copy.planCustomerId = this.$route.params.id
        copy.planOwnerId = this.user.username
        let newplan = await this.$store.dispatch("plan/copyPlan", copy)
        this.plans.push(newplan)
      }
    },
    routeTo(plan) {
      this.$router.push(`/plans/${plan.id}`)
    },
    editRoute(item) {
      this.$router.push(`/plans/${item}/edit`)
    },
    formatDate(val) {
      return dayjs(val).format('MM/DD/YYYY h:mm a')
    },
    async deletePlan(plan) {
      let deleted = await this.$store.dispatch("plan/removePlan", plan)
      if (deleted) {
        let ind = this.plans.findIndex(item => item.id === plan.id)
        this.plans.splice(ind, 1)
      }
    },
    async addPlan() {
      let plan = await this.$store.dispatch("plan/createPlan", {
        planCustomerId: this.customer.id,
        year: this.planYear
      })
      this.plans.push(plan)
      this.planYear = ""
    },
    deletePrompt(item) {
      this.$swal({
        title: `<strong>ARE YOU SURE YOU WANT TO DELETE PLAN <span style="color:#015a9c;">${item.name} ${item.year}</span>? </strong>`,
        customClass: "farm-market",
        showCancelButton: true,
        confirmButtonColor: '#c41731',
        cancelButtonColor: '#757575',
        confirmButtonText: 'DELETE',
        cancelButtonText: 'CANCEL',
        // focusCancel: true,
        allowOutsideClick: false
    })
    .then(async (result) => {
        if (result.isConfirmed) {
            this.deletePlan(item);
        }
    })
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("customer/emptyCustomer")
    next()
  }
}
</script>

<style lang="scss">
.copy-container {
  #swal-input1, #swal-input2{
    margin-top: .5rem;
    max-width: initial;
  }
  .swal1-input-label{
    display: block;
    text-align: left;
    color: #015a9c;
  }
}
</style>

<style lang="scss" scoped>
  .plans {
    ::v-deep  tr {
        cursor: pointer;
        td:nth-child(6) {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
        }
    }
  }
  .map-area{
    background-color: #015a9c;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    h2 {
      color: #fff;
      font-size: 2rem;
    }
  }
</style>